// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Image from 'gatsby-image';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import upperCase from 'lodash/upperCase';
import { AlphabetSoupWithDimensions } from 'react-alphabet-soup';

import Link from '../../plugins/i18n/components/Link';
import type { PrismicRestaurant } from '../../schema.flow';
import { TAG_COMING_SOON } from '../../constants/restaurants';

const styles = (theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '50vh',
    },
    '&:hover $image': {
      opacity: 0.9,
    },
    '&:hover $textOpacity': {
      opacity: 1,
    },
    '&:hover $subtitle': {
      opacity: 1,
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    opacity: 0,
    width: '100%',
    height: '100vh',
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 3}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  restaurantContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100vh',
    textAlign: 'center',
  },
  textOpacity: {
    opacity: 1,
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 3}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  title: {
    textTransform: 'uppercase',
    ...theme.typography.h1,
  },
  titleBeforeHover: {
    fontSize: '80px',
  },
  subtitle: {
    textTransform: 'uppercase',
    width: '100%',
    position: 'absolute',
    top: '60%',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 4}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    pointerEvents: 'none',
  },
});

export type Props = {
  node: ?PrismicRestaurant,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerButtonRow = ({ classes, node }: Props) => {
  if (!node) {
    return null;
  }

  const isComingSoon = !!(
    node &&
    node.tags &&
    node.tags.includes(TAG_COMING_SOON)
  );
  const BaseComponent = isComingSoon ? 'div' : ButtonBase;
  const BaseComponentProps = {};
  if (!isComingSoon) {
    BaseComponentProps.disableRipple = true;
    BaseComponentProps['aria-label'] = 'Restaurant';
  }
  const children = (
    <BaseComponent className={classes.wrapper} {...BaseComponentProps}>
      <div className={classes.imageContainer}>
        {node?.data?.landing_image?.localFile?.childImageSharp ? (
          <Image
            {...node?.data?.landing_image?.localFile?.childImageSharp}
            className={classes.image}
          />
        ) : null}
        <div
          className={classnames(
            classes.restaurantContainer,
            classes.textOpacity,
          )}
        >
          {node?.data?.restaurant_title?.text ? (
            <AlphabetSoupWithDimensions
              text={upperCase(node?.data?.restaurant_title?.text)}
              fontSize="48px"
              fontFamily="Hurme Geometric Sans"
              sorting="sortByY"
              transitionSpeedMultiplier={0.6}
              transitionStyle="random"
              wrapperClassName={classes.title}
              charactersClassName={classes.titleBeforeHover}
            />
          ) : null}
          {isComingSoon ? (
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.subtitle}
            >
              Coming Soon
            </Typography>
          ) : null}
        </div>
      </div>
    </BaseComponent>
  );

  return isComingSoon ? (
    children
  ) : (
    <Link to={`restaurant/${node.id}`}>{children}</Link>
  );
};

export default withStyles(styles)(DinerButtonRow);
