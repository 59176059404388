// @flow
import * as React from 'react';
import map from 'lodash/map';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import type { Theme } from '@material-ui/core/styles';
import DinerButtonColumn from './DinerButtonColumn';
import type { Query } from '../../schema.flow';

const styles = () => ({
  restaurantContainer: {
    flexDirection: 'column-reverse',
    '& > div:last-child': {
      backgroundColor: '#ECA68C',
    },
  },
});

export type Props = {
  data: ?$ElementType<Query, 'allPrismicRestaurant'>,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerButtonGridColumn = ({ classes, data }: Props) => (
  <Grid container className={classes.restaurantContainer}>
    {map(data?.edges, ({ node }) => (
      <Grid item l={12} key={node.id}>
        <DinerButtonColumn node={node} />
      </Grid>
    ))}
  </Grid>
);

export default withStyles(styles)(DinerButtonGridColumn);
