// @flow

import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Image from 'gatsby-image';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import Link from '../../plugins/i18n/components/Link';
import type { Query, PrismicRestaurant } from '../../schema.flow';
import { TAG_COMING_SOON } from '../../constants/restaurants';

const styles = (theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  restaurantContainer: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing.unit * 4,
  },
  text: {
    textTransform: 'uppercase',
  },
  textDisabled: {
    opacity: 0.5,
  },
  logo: {
    width: '70px',
    margin: 'auto',
    marginTop: theme.spacing.unit * 2,
  },
});

export type Props = {
  node: ?PrismicRestaurant,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerButtonColumn = ({ classes, node }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "26GLogo.png" }) {
          childImageSharp {
            fluid(maxHeight: 50) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(query: { logo: $ElementType<Query, 'file'> }) => {
      if (!node) {
        return null;
      }

      const isComingSoon = !!(
        node &&
        node.tags &&
        node.tags.includes(TAG_COMING_SOON)
      );
      const BaseComponent = isComingSoon ? 'div' : ButtonBase;
      const BaseComponentProps = {};
      if (!isComingSoon) {
        BaseComponentProps.disableRipple = true;
        BaseComponentProps.ariaLabel = 'Restaurant';
      }
      const children = (
        <BaseComponent className={classes.wrapper} {...BaseComponentProps}>
          <div className={classes.restaurantContainer}>
            {node?.data?.restaurant_title?.text ? (
              <Typography
                variant="h3"
                color="textPrimary"
                className={classnames(classes.text, {
                  [classes.textDisabled]: isComingSoon,
                })}
              >
                {node?.data?.restaurant_title?.text}
              </Typography>
            ) : null}
            {isComingSoon ? (
              <Typography
                variant="body1"
                color="textPrimary"
                className={classnames(classes.text, {
                  [classes.textDisabled]: isComingSoon,
                })}
              >
                Coming Soon
              </Typography>
            ) : null}
            <Image {...query?.logo?.childImageSharp} className={classes.logo} />
          </div>
        </BaseComponent>
      );
      return isComingSoon ? (
        children
      ) : (
        <Link to={`restaurant/${node.id}`}>{children}</Link>
      );
    }}
  />
);

export default withStyles(styles)(DinerButtonColumn);
