// @flow
import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import map from 'lodash/map';
import Grid from '@material-ui/core/Grid';
import Image from 'gatsby-image';

import DinerButtonRow from './DinerButtonRow';
import type { Query } from '../../schema.flow';

const styles = () => ({
  container: {
    position: 'relative',
    height: '100%',
    flexDirection: 'row-reverse',
    '& > div:last-child': {
      backgroundColor: '#ECA68C',
    },
  },
  logoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  logo: {
    width: '120px',
    marginTop: '-4vw',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles>>]: string },
  data: ?$ElementType<Query, 'allPrismicRestaurant'>,
};

const DinerButtonGridRow = ({ classes, data }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "26GLogo.png" }) {
          childImageSharp {
            fluid(maxHeight: 30) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(query: { logo: $ElementType<Query, 'file'> }) => {
      return (
        <Grid container className={classes.container}>
          <div className={classes.logoContainer}>
            <Image {...query?.logo?.childImageSharp} className={classes.logo} />
          </div>
          {map(data?.edges, ({ node }) => (
            <Grid item xs={12} key={node.id}>
              <DinerButtonRow node={node} />
            </Grid>
          ))}
        </Grid>
      );
    }}
  />
);

export default withStyles(styles)(DinerButtonGridRow);
