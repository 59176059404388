// @flow

import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import DinerButtonGridRow from '../components/DinerButtonGridRow';
import DinerButtonGridColumn from '../components/DinerButtonGridColumn';
import type { Query } from '../schema.flow';

const styles = () => ({
  root: {
    paddingLeft: 0,
    overflow: 'hidden',
    height: '100vh',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles>>]: string },
  width: Breakpoint,
  data: Query,
};

const HomePage = ({ classes, data: query, width }: Props) => (
  <SectionWrapper classes={{ root: classes.root }}>
    <PageHelmet data={query.prismicHomePage?.data} />
    {isWidthDown('xs', width) ? (
      <DinerButtonGridColumn data={query.allPrismicRestaurant} />
    ) : (
      <DinerButtonGridRow data={query.allPrismicRestaurant} />
    )}
  </SectionWrapper>
);

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any }>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(HomePage);

export const query = graphql`
  query HomePageQuery($prismicLocaleId: String) {
    prismicHomePage(lang: { eq: $prismicLocaleId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
      }
    }
    allPrismicRestaurant(
      limit: 3
      sort: { fields: [first_publication_date], order: ASC }
    ) {
      edges {
        node {
          id: prismicId
          tags
          first_publication_date
          data {
            restaurant_title {
              text
            }
            landing_image {
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 500, quality: 82) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
